<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template #item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span v-if="record._id">
          {{ record.about_subject.name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12">
            <v-subheader>{{ $t("About ptogram") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Institution") }}</td>
                    <td>{{ record.institution }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Faculty") }}</td>
                    <td>{{ record.faculty }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Department") }}</td>
                    <td>{{ record.department }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Main field") }}</td>
                    <td>{{ record.main_field }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Program") }}</td>
                    <td>{{ record.program }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Level") }}</td>
                    <td>{{ record.level }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Code") }}</td>
                    <td>{{ record.code }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("About subject") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Name") }}</td>
                    <td>{{ record.about_subject.name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Content area") }}</td>
                    <td>{{ record.about_subject.content_area }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Holder") }}</td>
                    <td>{{ record.about_subject.holder }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Holder lab") }}</td>
                    <td>{{ record.about_subject.holder_lab }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Stydy year") }}</td>
                    <td>{{ record.about_subject.stydy_year }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Semester") }}</td>
                    <td>{{ record.about_subject.semester }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Exams type") }}</td>
                    <td>{{ record.about_subject.exams_type }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Subject type") }}</td>
                    <td>{{ record.about_subject.subject_type }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="12">
            <v-subheader>{{ $t("Time alocated") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Course hours week") }}</td>
                    <td>{{ record.time_alocated.course_hours_week }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Laboratory hours week") }}</td>
                    <td>{{ record.time_alocated.laboratory_hours_week }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Total hours") }}</td>
                    <td>{{ record.time_alocated.total_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Study hours") }}</td>
                    <td>{{ record.time_alocated.study_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Library hours") }}</td>
                    <td>{{ record.time_alocated.library_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Lab hours") }}</td>
                    <td>{{ record.time_alocated.lab_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Tutoring hours") }}</td>
                    <td>{{ record.time_alocated.tutoring_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Exams hours") }}</td>
                    <td>{{ record.time_alocated.exams_hours }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Others hours") }}</td>
                    <td>{{ record.time_alocated.others_hours }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Prerequirements") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Syllabi") }}</td>
                    <td>{{ record.prerequirements.syllabi }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Competences") }}</td>
                    <td>{{ record.prerequirements.competences }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Requirements") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Course") }}</td>
                    <td>{{ record.requirements.course }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Lab") }}</td>
                    <td>{{ record.requirements.lab }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Skills") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("Professional") }}</td>
                    <td>{{ record.skills.professional }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Transversal") }}</td>
                    <td>{{ record.skills.transversal }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Objectives") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ $t("General") }}</td>
                    <td>{{ record.objectives.general }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Specific") }}</td>
                    <td>{{ record.objectives.specific }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Syllabus course content") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Course") }}</th>
                  <th>{{ $t("Teaching technics") }}</th>
                  <th>{{ $t("Observations") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(course, ind) in record.syllabus_course.content" :key="'course' + ind">
                    <td>{{ course.title }}</td>
                    <td>{{ course.teaching_technics }}</td>
                    <td>{{ course.observations }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Syllabus course bibliografy") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Name") }}</th>
                  <th>{{ $t("Link") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(bibliografy, ind) in record.syllabus_course.bibliografy" :key="'bib' + ind">
                    <td>{{ bibliografy.name }}</td>
                    <td>{{ bibliografy.link }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Syllabus laboratory content") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Course") }}</th>
                  <th>{{ $t("Teaching technics") }}</th>
                  <th>{{ $t("Observations") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(course, ind) in record.syllabus_laboratory.content" :key="'lab' + ind">
                    <td>{{ course.title }}</td>
                    <td>{{ course.teaching_technics }}</td>
                    <td>{{ course.observations }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Syllabus laboratory bibliografy") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Name") }}</th>
                  <th>{{ $t("Link") }}</th>
                </thead>
                <tbody>
                  <tr v-for="(bibliografy, ind) in record.syllabus_laboratory.bibliografy" :key="'biblab' + ind">
                    <td>{{ bibliografy.name }}</td>
                    <td>{{ bibliografy.link }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Relations") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            {{ record.relations }}
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Exams course") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Criteria") }}</th>
                  <th>{{ $t("Methods") }}</th>
                  <th>{{ $t("Weight") }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ record.exams.course.criteria }}</td>
                    <td>{{ record.exams.course.methods }}</td>
                    <td>{{ record.exams.course.weight }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Exams laboratory") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <th>{{ $t("Criteria") }}</th>
                  <th>{{ $t("Methods") }}</th>
                  <th>{{ $t("Weight") }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ record.exams.lab.criteria }}</td>
                    <td>{{ record.exams.lab.methods }}</td>
                    <td>{{ record.exams.lab.weight }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Requirements performance") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            {{ record.requirements_performance }}
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Approval department") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ record.approval.department.date }}</td>
                    <td>{{ record.approval.department.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Approval dean") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <tbody>
                  <tr>
                    <td>{{ record.approval.dean.date }}</td>
                    <td>{{ record.approval.dean.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Graduate syllabi",
          disabled: false,
          href: "/graduate-syllabi"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "Graduate") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('infuni_graduate_syllabi?where={"_id":"' + this.id + '"}')
        .then(response => {
          this.record = response.data._items[0]
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
